import { Icon, Tooltip } from '@material-ui/core';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import __ from 'underscore';
import { Button, Spinner } from 'reactstrap';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import * as actions from '../../../../Store/actions/index';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import ModalDeleteConfirmation from '../components/dataVisualization/modals/deleteConfirmation';
import ProbingEditModal from './editProbing';
import ModalMapProbing from './mapProbing';
import { ProbingInfoModal } from './modalProbing';

moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;
const sort_text = tlang('sort_text') || 'Sort';
const rows_per_page_text = tlang('rows_per_page_text') || 'Rows per page:';
const no_match_record_found = tlang('no_match_record_found') || 'Sorry, no matching records found';
const search_text = tlang('search_text') || 'Search';
const common_probing_list = tlang('common_probing_list') || 'Probing List';
const client_viewprobing_srno = tlang('client_viewprobing_srno') || 'Sr. No';
const client_campaign_name = tlang('client_campaign_name') || 'Campaign Name';
const client_viewprobing_nooflayer = tlang('client_viewprobing_nooflayer') || 'No of layers';
const client_viewprobing_noofsample = tlang('client_viewprobing_noofsample') || 'No of Sample';
const client_viewprobing_Initiation_Date =
	tlang('client_viewprobing_Initiation_Date') || 'Initiation Date';
const client_viewprobing_status = tlang('client_viewprobing_status') || 'Status';
const client_viewprobing_actions = tlang('client_viewprobing_actions') || 'Actions';
const client_probing_code = tlang('client_probing_code') || 'Probing Code';
const label_probing_name = tlang('label_probing_name') || 'Probing Name';
const client_probing_inputtext = tlang('client_probing_inputtext') || 'Input text';
const tooltip_view = tlang('tooltip_view') || 'View';
const tooltip_edit = tlang('tooltip_edit') || 'Edit';
const client_Close_Probing = tlang('client_Close_Probing') || 'Closed Probing';
const client_Reopen_Probing = tlang('client_Reopen_Probing') || 'Reopen Probing';
const active_project_text = tlang('active_project_text') || 'Active Project';
const client_select_campaign = tlang('client_select_campaign') || 'Select Campaign';
const delete_btn = tlang('delete_btn') || 'Delete';
const restore_btn = tlang('restore_btn') || 'Restore';
const restore_probing = tlang('restore_probing') || 'Restore Probing';
const delete_probing = tlang('delete_probing') || 'Delete Probing';
const delete_probing_are_you_sure =
	tlang('delete_probing_are_you_sure') || 'Are you sure you want to delete this Probing';
const restore_probing_are_you_sure =
	tlang('restore_probing_are_you_sure') || 'Are you sure you want to restore this Probing';
const client_open_probing_message =
	tlang('client_open_probing_message') || 'Are you sure you want to open this Probing';
const client_Open_Probing = tlang('client_Open_Probing') || 'Open Probing';
const client_no_licence_warning = 
	tlang('client_no_licence_warning') ||
	"It seems that you don't have any active licence. Go to My Profile >> Licence tab to add your licence.";

export default function ListProbing() {
	const dispatch = useDispatch();
	const history = useHistory();
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);
	const probingReducer = useSelector(state => state.probingReducer);
	const campaignReducer = useSelector(state => state.campaignReducer);
	const [loading, setLoading] = useState(false);
	const [probingList, setProbingList] = useState([]);
	const [campaignList, setCampaignList] = useState([]);
	const [campaignId, setCampaignId] = useState('');
	const [showViewProbing, setShowViewProbing] = useState(false);
	const [showEditProbing, setShowEditProbing] = useState(false);
	const [showDeleteProbing, setShowDeleteProbing] = useState(false);
	const [showRestoreProbing, setShowRestoreProbing] = useState(false);
	const [showUnlockProbing, setShowUnlockProbing] = useState(false);
	const [selectedProbing, setSelectedProbing] = useState({});
	const [userAccess, setUserAccess] = useState({});
	const [showMap, setShowMap] = useState(false);

	const isAdmin = useMemo(() => authReducer.userData.email === authReducer.userData.owner, [
		authReducer.userData?.email,
		authReducer.userData?.owner,
	]);

	const projectName = useMemo(() => {
		if (!Array.isArray(authReducer.companyList)) return '-';
		const projectId = authReducer.userData.projectid;
		const project = authReducer.companyList.find(c => c.projectid === projectId);
		return project?.projectname || '-';
	}, [authReducer.companyList, authReducer.userData.projectid]);

	const goToaddLicence = () => {
		localStorage.setItem("client_request_tab", 7)
		history.push('/client/profile')
	}	

	const options = useMemo(
		() => ({
			filterType: 'dropdown',
			selectableRows: 'none',
			print: false,
			download: false,
			viewColumns: false,
			filter: false,
			search: false,
			responsive: 'stacked',
			textLabels: {
				body: {
					noMatch: no_match_record_found,
					toolTip: sort_text,
				},
				pagination: {
					rowsPerPage: rows_per_page_text,
				},
				toolbar: {
					search: search_text,
				},
			},
			customToolbar: () => {
				return (
					<>
					<CustomToolBar>
						<p className="label">{active_project_text}</p>
						<p>{projectName}</p>
						<p className="label">{client_select_campaign}</p>
						<Select
							value={campaignList.find(c => c.value === campaignId)}
							onChange={value => setCampaignId(value?.value || '')}
							options={campaignList}
							isClearable={true}
							className="react-select"
							styles={{
								container: provided => ({
									...provided,
									width: 'clamp(200px, 35%, 50vw)',
									zIndex: 101,
								}),
							}}
						/>
						<Tooltip title="map">
							<Button
								color="success"
								onClick={() => setShowMap(true)}
								className={'add-new-btn btn btn-outline-success'}
								outline={true}
							>
								<Icon>room</Icon>
							</Button>
						</Tooltip>
					</CustomToolBar>
					<LicenceWarning>
						<p onClick={()=>goToaddLicence(history)} className="label">
						{__.isEmpty(authReducer.userData.lic_exp_dtl) 
						?  client_no_licence_warning : "" 
						}
						</p>
					</LicenceWarning>
					</>
				);
			},
		}),
		[projectName, campaignList]
	);

	const columns = useMemo(
		() => [
			{
				name: client_viewprobing_srno,
				options: {
					filter: false,
					sort: false,
				},
			},
			{
				name: 'Campaign Name',
				label: client_campaign_name,
				options: { filter: false },
			},
			{
				name: 'Probing Code',
				label: client_probing_code,
				options: { filter: false },
			},
			{
				name: 'Probing Name',
				label: label_probing_name,
				options: { filter: false },
			},
			{
				name: 'Input text',
				label: client_probing_inputtext,
				options: { filter: false },
			},
			{
				name: 'No of layers',
				label: client_viewprobing_nooflayer,
				options: { filter: false },
			},
			{
				name: 'No of Sample',
				label: client_viewprobing_noofsample,
				options: { filter: false },
			},
			{
				name: 'Initiation Date',
				label: client_viewprobing_Initiation_Date,
				options: { filter: false },
			},
			{
				name: 'Status',
				label: client_viewprobing_status,
				options: { filter: false },
			},
			{
				name: client_viewprobing_actions,
				options: {
					filter: false,
					sort: false,
					customBodyRender: value => {
						if (value) {
							const tooltipData =
								value.status === '' || value.status === 'Open' ? delete_btn : restore_btn;
							return (
								<>
									{userAccess.view_probing && (
										<Button
											className={'system-btn clear-border'}
											outline={true}
											color={'success'}
											onClick={() => {
												// setCampaignId(value.campaignid);
												setSelectedProbing(value);
												setShowViewProbing(true);
											}}
										>
											<Tooltip title={tooltip_view}>
												<Icon>remove_red_eye</Icon>
											</Tooltip>
										</Button>
									)}

									{userAccess.probing_management && value.endstatus && value.status !== 'Delete' && (
										<Button
											className={'system-btn clear-border'}
											color="success"
											onClick={() => {
												setSelectedProbing(value);
												setShowUnlockProbing(true);
											}}
											outline={true}
										>
											<Tooltip title={client_Reopen_Probing}>
												<Icon>lock</Icon>
											</Tooltip>
										</Button>
									)}

									{userAccess.probing_management &&
										// !_this.state.isclosed &&
										!value.endstatus && (
											<>
												{value.status !== 'Delete' && (
													<Button
														className={'system-btn clear-border'}
														outline={true}
														color={'success'}
														onClick={() => {
															setSelectedProbing(value);
															setShowEditProbing(true);
														}}
													>
														<Tooltip title={tooltip_edit}>
															<Icon>edit</Icon>
														</Tooltip>
													</Button>
												)}
											</>
										)}
									{userAccess.probing_management &&
										// !_this.state.isclosed &&
										!value.endstatus && (
											<>
												{value.status !== 'Delete' && (
													<Button
														className={'system-btn clear-border'}
														outline={true}
														color={'success'}
														onClick={() => {
															setSelectedProbing(value);
															setShowDeleteProbing(true);
														}}
													>
														<Tooltip title={tooltipData}>
															<Icon>delete</Icon>
														</Tooltip>
													</Button>
												)}
											</>
										)}

									{userAccess.probing_management && value.status === 'Delete' && (
										<Button
											className={'system-btn clear-border'}
											outline={true}
											color={'success'}
											onClick={() => {
												setSelectedProbing(value);
												setShowRestoreProbing(true);
											}}
										>
											<Tooltip title={tooltipData}>
												<Icon>restore</Icon>
											</Tooltip>
										</Button>
									)}
								</>
							);
						}
					},
				},
			},
		],
		[userAccess?.view_project, userAccess?.modify_project, isAdmin, authReducer?.userData?.role_id]
	);

	useEffect(() => {
		setUserAccess(authReducer.userData.useraccesspage || {});
	}, [authReducer.userData?.useraccesspage]);

	useEffect(() => {
		/* Load init actions */
		setLoading(true);
		const reqPrj = {
			id: authReducer.userData.projectid,
			company: authReducer.userData.company_name,
		};
		dispatch(actions.getprojectDetails(apolloClient.project, reqPrj));
		/* get probing list */
		const reqProb = {
			campaignid: '',
			projectid: authReducer.userData.projectid,
		};
		// dispatch(actions.probingListForView(apolloClient.probing, reqProb));
		dispatch(actions.getProbingList(apolloClient.probing, reqProb));

		/* get active projects list */
		dispatch(actions.getActiveProjectList(apolloClient.project, []));

		/* get campaign list */
		const reqCamp = {
			companyname: authReducer.userData.company_name,
			projectid: authReducer.userData.projectid,
			other_company: authReducer.userData.other_company,
			isadmin: isAdmin,
		};
		dispatch(actions.getCampaignList(apolloClient.campaign, reqCamp));
	}, []);

	useEffect(() => {
		if (!Array.isArray(probingReducer.getProbingList)) return;
		const selectedProbing = probingReducer.getProbingList.filter(
			p => campaignId === '' || p.campaignid === campaignId
		);
		setProbingList(
			selectedProbing.map((p, idx) => [
				idx + 1,
				p.campaignname,
				p.probingcode,
				p.probingname || p.probingcode,
				p.inputtext,
				p.nooflayer,
				p.noofsample,
				p.probingDate && moment.unix(p.probingDate).isValid() ? moment.unix(p.probingDate/1000).format('DD/MM/YYYY') : moment(p.startdate).format('DD/MM/YYYY'),
				<>
					{p.status === 'Delete' ? (
						<span className={'statusDeleted'}>Deleted</span>
					) : p.status === 'Pending' ? (
						<span className={'statusPending'}>Pending</span>
					) : p.endstatus ? (
						<span className={'statusClose'}>Closed</span>
					) : (
						<span className={'statusOpen'}>Open</span>
					)}
				</>,
				p,
			])
		);
		setLoading(false);
	}, [probingReducer.getProbingList, campaignId]);

	// console.log('authReducer', authReducer);
	useEffect(() => {
		if (!Array.isArray(campaignReducer.campaignList)) return;
		// create campaign list
		const campaignList = campaignReducer.campaignList.map(c => ({
			value: c.campaignid,
			label: c.title,
		}));
		setCampaignList(campaignList);
	}, [campaignReducer.campaignList]);

	function handleStatusProbing(id, newStatus) {
		const requestParams = {
			probingid: id,
			projectid: authReducer.userData.projectid,
			campaignid: selectedProbing.campaignid,
			probingStatus: newStatus,
		};
		dispatch(actions.deleteProbing(apolloClient.probing, requestParams));
		setShowRestoreProbing(false);
		setShowDeleteProbing(false);
	}

	function handleOpen() {
		const requestParams = {
			projectid: authReducer.userData.projectid,
			campaignid: selectedProbing.campaignid || '',
			probingid: selectedProbing.probingid,
			reasonid: '',
			reasonname: '',
			detail: '',
			endstatus: false,
			campaignidForList: selectedProbing.campaignid || '',
		};
		dispatch(actions.closeProbing(apolloClient.probing, requestParams));
		setShowUnlockProbing(false);
	}

	return (
		<>
			{showMap && <ModalMapProbing isOpen={showMap} onClose={() => setShowMap(false)} />}
			<div style={{ isolation: 'isolate' }}>
				<h3 className="page-title" style={{ marginBottom: '20px' }}>
					{common_probing_list}
				</h3>
				{loading ? (
					<div className="div-spinner">
						<Spinner size="500" className="success" />
					</div>
				) : (
					<MUIDataTable data={probingList} columns={columns} options={options} />
				)}
				{showViewProbing && (
					<ProbingInfoModal
						isOpen={showViewProbing}
						onClose={() => setShowViewProbing(false)}
						probingIds={probingList.map(p => p[9].probingid)}
						selectedId={selectedProbing.probingid}
					/>
				)}
				<ModalDeleteConfirmation
					isOpen={showRestoreProbing}
					onClose={() => setShowRestoreProbing(false)}
					deleteData={{
						title: restore_probing,
						deleteMessage: restore_probing_are_you_sure,
						actionName: '',
						objectName: selectedProbing.probingcode,
						buttonConfirmText: restore_btn,
					}}
					onConfirm={() => handleStatusProbing(selectedProbing.probingid, 'Open')}
				/>
				<ModalDeleteConfirmation
					isOpen={showDeleteProbing}
					onClose={() => setShowDeleteProbing(false)}
					deleteData={{
						title: delete_probing,
						deleteMessage: delete_probing_are_you_sure,
						actionName: '',
						objectName: selectedProbing.probingcode,
						buttonConfirmText: delete_btn,
					}}
					onConfirm={() => handleStatusProbing(selectedProbing.probingid, 'Delete')}
				/>
				{showEditProbing && (
					<ProbingEditModal
						isOpen={showEditProbing}
						onClose={() => setShowEditProbing(false)}
						probingIds={probingList.map(p => p[9].probingid)}
						selectedId={selectedProbing.probingid}
					/>
				)}
				{showUnlockProbing && (
					<ModalDeleteConfirmation
						isOpen={showUnlockProbing}
						onClose={() => setShowUnlockProbing(false)}
						deleteData={{
							title: client_Open_Probing,
							deleteMessage: client_open_probing_message,
							actionName: '',
							objectName: selectedProbing.probingcode,
						}}
						onConfirm={() => handleOpen()}
					/>
				)}
			</div>
		</>
	);
}

const CustomToolBar = styled.div`
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	flex-wrap: wrap;
	align-items: center;
	p {
		margin: 0;
		padding: 0;
	}
	.label {
		font-weight: 700;
		color: var(--clr-aexdo);
	}
`;

const LicenceWarning = styled.div`
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
	align-items: center;
	p {
		margin: 0;
		padding: 0;
		font-size: 15px;
		cursor: pointer;
	}
	.label {
		font-weight: 700;
		color: var(--clr-aexdo);
	}
`;

